import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";
import { Link, graphql, useStaticQuery } from "gatsby";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import Footer from "../components/Footer/Footer";

import "./ArticlePage.css";

import { GlobalStateContext } from "../context/GlobalContextProvider";

// import authors from "../../authors.json";

config.autoAddCss = false;

const utils = require("../services/utils");

const createSlug = (title) => {
  const slug = title.replace(/\W+/g, "-").toLowerCase();
  return slug;
};

export const query = graphql`
  query ($articleId: String!) {
    contentfulArticle(id: { eq: $articleId }) {
      id
      title
      metaTitle
      metaDescription {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      updatedAt
      thumbnailImageUrl
    }
  }
`;

const capitalizeHeading = (heading) => {
  const lowercaseWords = ["to", "for", "and", "in", "of", "the"]; // Add more words as needed
  const words = heading.split(" ");

  const capitalizedWords = words.map((word) => {
    if (lowercaseWords.includes(word.toLowerCase())) {
      return word.toLowerCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  });

  return capitalizedWords.join(" ");
};

function removeAfterPipeInHTML(inputString) {
  // Split the inputString at the "|" character
  const parts = inputString.split("|");

  // Take the first part (before the "|") and trim any leading/trailing whitespace
  const result = parts[0].trim();

  return result;
}

const getHeading = (html) => {
  let h1Content = "";

  html = html.replace(/<h1>(.*?)<\/h1>/i, (match, group) => {
    h1Content = group;
    return ""; // Remove the h1 tag from the HTML
  });

  return h1Content;
};

const getContentBeforeFirstH2 = (html) => {
  const result = {
    image: "",
    content: "",
  };

  html = html.replace(/<h1>(.*?)<\/h1>/i, (match, group) => {
    return ""; // Remove the h1 tag from the HTML
  });

  // Use a regular expression to match content up to the first <h2> tag
  const regex = /([\s\S]*?)<h2\b[^>]*>/;
  const match = regex.exec(html);

  if (match) {
    // match[1] contains the content up to the first <h2> tag
    result.content = match[1];
  }

  // Add rel="nofollow" and target="_blank" to <a> tags
  result.content = result.content.replace(
    /<a\s+(?![^>]*rel=['"]nofollow['"])(?![^>]*href=['"](https:\/\/travel-lingual\.com\/)([^"']*))([^>]*)>/gi,
    '<a $3 rel="nofollow" target="_blank">'
  );

  // // Add loading="lazy" to <img> tags except the first 3
  // let imageCount = 0;

  // result.content = result.content.replace(
  //   /<img\s+(?![^>]*loading=['"]lazy['"])([^>]*)>/gi,
  //   (match, group) => {
  //     if (imageCount < 1) {
  //       const srcMatch = /src=["'](.*?)["']/i.exec(group);
  //       const imageUrl = srcMatch[1];

  //       // Call the transformImageUrl method to modify the image URL
  //       const transformedImageUrl = transformImageUrl(imageUrl, 790, 300);

  //       // Replace the original src attribute with the transformed image URL
  //       group = group.replace(
  //         /src=["'](.*?)["']/i,
  //         `src="${transformedImageUrl}"`
  //       );

  //       result.image = `<img ${group} class="my-recommendation-image">`;
  //     } else {
  //       result.content += `<img ${group} loading="lazy" style="height: auto; width: 100%; aspect-ratio: 16 / 11;">`;
  //     }
  //     imageCount++;
  //     return ""; // Remove the original image tag
  //   }
  // );

  return result;
};

const transformImageUrl = (imageUrl, width = 120, height = 80) => {
  // Check if the URL already contains parameters
  if (imageUrl.includes("?")) {
    // If parameters are already present, add the new parameters with '&'
    return `${imageUrl}&tr=w-${width},h-${height},fo-auto`;
  } else {
    // If no parameters are present, add the new parameters with '?'
    return `${imageUrl}?tr=w-${width},h-${height},fo-auto`;
  }
};

const formatHtml = (html) => {
  html = html.replace(/<h1>(.*?)<\/h1>/i, (match, group) => {
    return ""; // Remove the h1 tag from the HTML
  });

  // Use a regular expression to match content up to the first <h2> tag
  const regex = /(<h2\b[^>]*>[\s\S]*?<\/h2>)([\s\S]*)/;
  const match = regex.exec(html);

  if (match) {
    // match[1] contains the content up to the first <h2> tag
    html = match[0];
  }

  // Add rel="nofollow" and target="_blank" to <a> tags
  html = html.replace(
    /<a\s+(?![^>]*rel=['"]nofollow['"])(?![^>]*href=['"](https:\/\/travel-lingual\.com\/)([^"']*))([^>]*)>/gi,
    '<a $3 rel="nofollow" target="_blank">'
  );

  html = html.replace(
    /<img\s+(?![^>]*loading=['"]lazy['"])([^>]*)>/gi,
    (match, group) => {
      return `<img ${group} loading="lazy" style="height: auto; width: 100%;">`;
    }
  );

  // Add id attribute to all h2 tags
  html = html.replace(/<h2>(.*?)<\/h2>/gi, (match, group) => {
    // Create an id based on the text content
    const id = group
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]/g, ""); // Remove punctuation

    // Add the id attribute to the h2 tag
    return `<h2 id="${id}">${group}</h2>`;
  });

  return html;
};

const ArticleTemplate = ({ pageContext, data }) => {
  const location = useLocation();

  const context = React.useContext(GlobalStateContext);

  const [selectedItemId, setSelectedItemId] = useState("");

  const { id, content, updatedAt, author } = data.contentfulArticle;

  useEffect(() => {
    if (selectedItemId) {
      const selectedSection = document.getElementById(selectedItemId);
      if (selectedSection) {
        selectedSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [selectedItemId]);

  const h1Content = getHeading(content.childMarkdownRemark.html);

  const descriptionContent = getContentBeforeFirstH2(
    content.childMarkdownRemark.html
  );

  // const authorProfile = authors.find((_) => author && _.name === author[0]);

  return (
    <Page>
      <TopBar showSearch={true} />
      <Section>
        <div className="article">
          <div className="article-content">
            {h1Content && (
              <h1 dangerouslySetInnerHTML={{ __html: h1Content }} />
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: descriptionContent.content,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: formatHtml(content.childMarkdownRemark.html),
              }}
            />
          </div>
        </div>
      </Section>
      <Footer />
    </Page>
  );
};

export default ArticleTemplate;

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data.contentfulArticle;
  return (
    <SEO
      index
      follow
      title={metaTitle}
      description={
        metaDescription && metaDescription.childMarkdownRemark.rawMarkdownBody
      }
    />
  );
};
